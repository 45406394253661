<template>
    <BaseField :col="col" :label="label" :id="id" :required="required">
        <textarea
            class="form-control"
            :class="{ 'is-invalid': hasError }"
            :id="id"
            :value="model"
            :rows="rows"
            @input="emitUpdate($event.target.value)"
        ></textarea>

    </BaseField>
</template>

<script>
import {defineComponent} from 'vue';
import BaseField from './BaseField.vue';

export default defineComponent({
    components: {
        BaseField
    },
    extends: BaseField,
    props: {
        model: String,
        rows: {
            type: Number,
            default: 2
        }
    }
});
</script>
